import React, { useState, useEffect } from 'react';


function ProductsPage() {

    const [products, setProducts]=useState([]);
    useEffect(() => {
    fetch('https://jo27uabbna.execute-api.eu-west-1.amazonaws.com/api/products')
        .then((response) => response.json())
        .then((data) => {
            console.log(data);
            const values=JSON.parse(data.body)
            setProducts(values)
        })
        .catch((err) => {
            console.log(err.message);
        });
    }, []);
    return (
    <div class="flex flex-wrap">
      {
      products.map((item) => (
        <div class="rounded border-2 border-rose m-4 p-4">
            <div class="text-lg font-bold">{item.product}</div>
            <div><img style={{"height":"200px"}} src={item.image_url}></img></div>
            <div>
            {
            item.labels.map((label)=> (
                <span class="bg-lime-200 text-sm rounded p-2 m-2">{label.label} [{label.confidence}]</span>
                ))
            }
            </div>
        </div>
      ))
      }
    </div>
  );
}

export default ProductsPage;