import React, { useState, useEffect } from 'react';


function LabelsPage() {

    const [labels, setLabels]=useState([]);
    useEffect(() => {
    fetch('https://jo27uabbna.execute-api.eu-west-1.amazonaws.com/api/labels')
        .then((response) => response.json())
        .then((data) => {
            console.log(data);
            const values=JSON.parse(data.body)
            setLabels(values)
        })
        .catch((err) => {
            console.log(err.message);
        });
    }, []);

    useEffect(() => {
      console.log("labels set", labels)
      const cats = [...new Set(labels.map(q => q.cat))];
      console.log("cats",cats)
    }, [labels]);
  
    return (
    <div class="flex flex-wrap">
      {
      labels.map((label) => (
        <span class="rounded bg-sky-200 text px-1 m-1">
          <span class="font-bold">{label.label}</span> [{label.cat}]
        </span>
      ))
      }
    </div>
  );
}

export default LabelsPage;