import React from "react";

import { HashRouter as Router, Routes, Route } from "react-router-dom";

import './App.css';
import { generateClient } from 'aws-amplify/api';
import { useEffect, useState } from 'react';

// The path of your codegen files. You may need to update this.
import { publish } from './graphql/mutations';
import { subscribe } from './graphql/subscriptions';
import { Amplify } from 'aws-amplify';
import config from './aws-exports.js';

import ProductsPage from './Pages/ProductsPage.js';
import HomePage from './Pages/HomePage.js';
import LabelsPage from './Pages/LabelsPage.js'

import {Buffer} from 'buffer';

import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';

import 'react-html5-camera-photo/build/css/index.css';

import AWS from 'aws-sdk'


const S3_BUCKET ='arexons-rekognition-labels-bucket';
const REGION ='eu-west-1';


AWS.config.update({
    accessKeyId: 'AKIAXYKJV3BA3PHQDREE',
    secretAccessKey: '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'
})

const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET},
    region: REGION,
})



Amplify.configure(config);

const client = generateClient();


function App() {


  return (
    <Router>
    <div>
      <Routes>
        <Route exact path="/" element={ <HomePage/>} />
        <Route path="/products" element={<ProductsPage/>} />
        <Route path="/labels" element={<LabelsPage/>} />
      </Routes>
    </div>
  </Router>
  );
}

export default App;
