import logo from '../logo.svg';
import { generateClient } from 'aws-amplify/api';
import { useEffect, useState } from 'react';

// The path of your codegen files. You may need to update this.
import { publish } from '../graphql/mutations';
import { subscribe } from '../graphql/subscriptions';
import { Amplify } from 'aws-amplify';
import config from '../aws-exports.js';


import {Buffer} from 'buffer';

import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';

import 'react-html5-camera-photo/build/css/index.css';

import AWS from 'aws-sdk'


const S3_BUCKET ='arexons-rekognition-labels-bucket';
const REGION ='eu-west-1';


AWS.config.update({
    accessKeyId: 'AKIAXYKJV3BA3PHQDREE',
    secretAccessKey: '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'
})

const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET},
    region: REGION,
})



Amplify.configure(config);

const client = generateClient();


function HomePage() {

  let name = 'channel';

  const [data, setData] = useState('');

  const [dataUri, setDataUri] = useState('');

  const [fname,setFname]=useState('');

  const [presentation, setPresentation]=useState(true);
  const [makePhoto, setMakePhoto]=useState(false);
  const [makePhoto2, setMakePhoto2]=useState(0);
  const [loading, setLoading]=useState(false);
  const [showResult, setShowResult]=useState(false);

  const [winner, setWinner]=useState({});
  const [items, setItems]=useState([]);

  const [retVal, setRetVal]=useState();


  function generateFname() {
    const rnd=Math.random();
    const fn=Math.floor(rnd*10000)+"_"+Date.now()+".jpg";
    //console.log("in generate for",fn)
    setFname(fn);
  }

  async function handleTakePhoto (dataUri) {
    setLoading(true)
    setMakePhoto(false)
    setShowResult(false)
    // Do stuff with the photo...
    console.log('takePhoto');
    //console.log(dataUri);

    if(dataUri!=null)
      setDataUri(dataUri)
  }

  async function onMakePhoto() {
    console.log("Make photo")
    setMakePhoto(true)
    setPresentation(false)
    setLoading(false)
    setShowResult(false)
    console.log("Should be able to make photo")
  }
  async function onPresentation() {
    setMakePhoto(false)
    setPresentation(true)
    setLoading(false)
    setShowResult(false)
  }

  // subscribe to events

  useEffect(() => {
    console.log("filename set to....",fname)
    if(fname!="") {
      const base64Data = new Buffer.from(dataUri.replace(/^data:image\/\w+;base64,/, ""), 'base64');
      let params = {
        Body: base64Data,
        Bucket: S3_BUCKET,
        Key: fname,
        ContentEncoding: 'base64',
        ContentType: 'image/jpeg'
      };

      console.log("data")
      if(base64Data.length>0) {
        console.log("send2s3")
        myBucket.putObject(params)
            .on('httpUploadProgress', (evt) => {
                //setProgress(Math.round((evt.loaded / evt.total) * 100))
                //console.log(Math.round((evt.loaded / evt.total) * 100))
            })
            .send((err) => {
            //setLoading(false);
            if (err) {
                console.log(err)
                setLoading(false);
            }
            })
        }
    }

  }, [fname]);

  useEffect(() => {
    //console.log("dataUri set to....",dataUri)
    generateFname();
  }, [dataUri]);

  useEffect(() => {
    console.log("retVal set to....",retVal)
    console.log("ATTTTTENZIONE")
    //console.log("FNAME",fname)
    if(retVal) {
      const channel=retVal.subscribe.name
      const message=JSON.parse(retVal.subscribe.data)
      const receivedFilename=message.filename
      //console.log(message)
      console.log("RECE", receivedFilename)
      if(receivedFilename === fname) {
        setLoading(false)
        setShowResult(true)
        if(message.winner) {
          setWinner(message.winner)
          setItems(message.items)
        }
        else {
          //handle not found
        }
      }
      else 
        console.log("other filename")
    }

  }, [retVal]);



  useEffect(() => {
    //console.log("USEEFFECT")
    const sub = client.graphql({ query: subscribe, variables: { name } }).subscribe({
      next: ({ data }) => {

        setRetVal(data);
        /*
        console.log("ATTTTTENZIONE")
        console.log(data)
        console.log(data.subscribe)
        console.log("FNAME",fname)
        const channel=data.subscribe.name
        const message=JSON.parse(data.subscribe.data)
        const receivedFilename=message.filename

        console.log("RECE", receivedFilename)
        if(receivedFilename === fname) {
          alert("received response!!!")
        }
        else 
          console.log("other filename")
        */
        
      },
      //setReceived(data.subscribe.data),
      error: (error) => console.warn(error),
    });
    return () => sub.unsubscribe();
  }, [name]);

  return (
    <div>
      <div class="justify-center">
        <img class="m-auto"  src="/arexons_logo.png" width="80"></img>
        <img class="m-auto"  src="/solution_finder.png" width="80"></img>
      </div>
      <h1 class="mb-4 text-2xl font-extrabold leading-none tracking-tight text-gray-900 md:text-3xl lg:text-3xl dark:text-white text-center">
      Arexons AI Solution Finder
      </h1>
      {presentation &&
      <div class="text-center">
        <div class="text-center">Dust? Stain in your car? Don't worry, take a photo and you will get the Arexons product name
          that will solve your problem.</div>
          <button class="mt-3 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full"
          onClick={onMakePhoto}>
            Open camera
          </button>
        </div>
      }
      {makePhoto &&
      <div class="text-center">
        <Camera imageType = {IMAGE_TYPES.JPG}
          idealFacingMode = {FACING_MODES.ENVIRONMENT}
          onTakePhoto = { (dataUri) => { handleTakePhoto(dataUri); } }
        />
          <button class="mt-3 bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded-full"
          onClick={onPresentation}>
            Hide camera
          </button>
        </div>
      }

      {loading && 
        <div class="justify-center">    
          <img class="m-auto" src="/spinner.gif"></img>
        </div>
      }

      {showResult &&
      <div class="text-center">
        <div class="flex justify-center">
          {
            winner.product!=""? (
            <div>
                <div class="">
                  <h2 class="mb-4 text-xl font-extrabold leading-none tracking-tight text-gray-900 md:text-xl lg:text-xl dark:text-white text-center">
                    {winner.product}
                  </h2>
                  <div>
                    <img src={winner.image_url} style={{"height":"200px"}}></img>
                  </div>
                  <a href={winner.url} target="_blank">
                    <button class="mt-3 bg-orange-500 hover:bg-orange-700 text-white font-bold py-2 px-4 rounded-full">
                    Goto website
                    </button>
                  </a>
                </div>
                <div class="">
                  <h2 class="mb-4 text-sm font-extrabold leading-none tracking-tight text-gray-900 md:text-sm lg:text-sm dark:text-white text-center">
                    keywords found in image:
                  </h2>
                  <table width="300">
                    <tbody>
                    {items.map((item) => (
                      <tr>
                        <td>{item.label}</td>
                        <td>
                          <span class="bg-green-100 text-green-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-green-900 dark:text-green-300">
                          {item.confidence.toFixed(2)} %
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <button class="mt-3 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full"
                  onClick={onMakePhoto}>
                  New camera search
                </button>
              </div>
            </div>
            ):
            (
              <div>
                <div>
                  <div>No suitable product found, please try with another photo</div>
                </div>

                <div class="">
                  <h2 class="mb-4 text-sm font-extrabold leading-none tracking-tight text-gray-900 md:text-sm lg:text-sm dark:text-white text-center">
                    keywords found in image:
                  </h2>
                  <table width="300">
                    <tbody>
                    {items.map((item) => (
                      <tr>
                        <td>{item.label}</td>
                        <td>
                          <span class="bg-green-100 text-green-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-green-900 dark:text-green-300">
                          {item.confidence.toFixed(2)} %
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <button class="mt-3 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full"
                  onClick={onMakePhoto}>
                  New camera search
                </button>
              </div>
            </div>
            )
          }
        </div>
      </div>
      
      }
    
      
    </div>

  );
}

export default HomePage;
